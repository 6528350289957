// hooks/useFetchChatContent.js
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { StateContext } from '../components/StateContextProvider';
import { useSettings } from './useSettings';
import { triggerAPIRequest } from '../services/api.methods';

import config from '../config';

export const useFetchChatContent = () => {
  const navigate = useNavigate();
  const { setChatContent, setShowCharacterSelection, setErrorMsg } = useContext(StateContext);
  const getSettings = useSettings();

  return useCallback(async (sessionId, currentSessionIndex) => {
    try {
      if (config.VERBOSE_SUPERB === 1)
        console.log("123 EXecuting useFetchChatContent hook useCallback fetchChatContent with sessionId: ", sessionId);

      const userInput = { "session_id": sessionId };
      const response = await triggerAPIRequest("api/db", "provider.db", "db_get_user_session", userInput, getSettings);

      // if session doesn't exist
      if (response.code !== 200) {
        setChatContent((prevChatContent) => {
          const updatedChatContent = [...prevChatContent];
          updatedChatContent[currentSessionIndex].db_session_id = "";
          return updatedChatContent;
        });
        navigate(`/`);
        setShowCharacterSelection(true);
        return null;
      } else {
        // Update this part to use the new structure
        const messages = response.message.result.messages || [];
        setChatContent((prevChatContent) => {
          const updatedChatContent = [...prevChatContent];
          updatedChatContent[currentSessionIndex] = {
            ...response.message.result,
            messages: messages.map(message => ({
              ...message,
              messageId: message.message_id || 0,
              isUserMessage: message.sender === 'User'
            }))
          };
          updatedChatContent[currentSessionIndex].db_session_id = sessionId;
          updatedChatContent[currentSessionIndex].ai_character_name = response.message.result.ai_character_name;
          return updatedChatContent;
        });
        setShowCharacterSelection(false);
        return response.message.result;
      }
    } catch (error) {
      setErrorMsg("Problem with fetching data. Try again.");
      console.error('Failed to fetch chat content', error);
      return null;
    }
  }, [getSettings, setChatContent, setShowCharacterSelection, setErrorMsg, navigate]);
};
