// ChatMessage.js
import React, { useState, useEffect, useRef, useContext } from 'react';

import { StateContext } from './StateContextProvider';

import { useCurrentSessionId } from '../hooks/useCurrentSession';

import ChatImageModal from './ChatImageModal';

import DOMPurify from 'dompurify';
import { marked } from 'marked';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css'; // Dark theme
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-bash';
// has to be after prism - so we overwrite some css
import './css/ChatMessage.css';

import { convertFileAndImageLocationsToAttached } from '../utils/misc';
import { getSvgIcon } from '../utils/svg.icons.provider';

import { characters } from './ChatCharacters';

import { useSettings } from '../hooks/useSettings';
import { triggerAPIRequest, updateSessionInDB, generateImage } from '../services/api.methods';
import { getGeneralShowMessageInfoBottomRight } from '../utils/configuration';
import { formatDate } from '../utils/misc';

// TODO MOVE TO CONFIG LATER
const ERROR_MESSAGE_FOR_TEXT_GEN = "Error in Text Generator. Try again!";

// memoized version!
const ChatMessage = React.memo(({ index, message, isLastMessage, contextMenuIndex, setContextMenuIndex, isFloating = false, allSessionImages = [] }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const messageRef = useRef(null);
  const avatarSrc = message.isUserMessage
    ? '/imgs/UserAvatar.jpg'
    : `/imgs/${message.aiCharacterName}.png`;
  // set section for images and filter out placeholders
  const [validImageLocations, setValidImageLocations] = useState(
    message.image_locations ? message.image_locations.filter(src => src !== "image_placeholder_url") : []
  );

  // this is to make sure that if file name is empty - it will not crash app
  const filterValidFiles = (files) => {
    if (!Array.isArray(files)) return [];
    return files.filter(src =>
      typeof src === 'string' && (src.endsWith('.pdf') || src.endsWith('.txt'))
    );
  };

  // this is to show audio player for attached audio files
  // we check extensions - but also if it doesn't start with /storage/emulated - because it's local file in android (recording)
  const isValidAudioFile = (src) => {
    const validExtensions = ['.ogg', '.mp3', '.wav', '.mp4', '.m4a', '.webm', '.opus'];
    return validExtensions.some(ext => src.endsWith(ext)) && !src.startsWith('/storage/emulated') && !src.startsWith('file');
  };

  // to understand if it's audio file stored in android phone
  const isAndroidStoredAudioFile = (src) => {
    const validExtensions = ['.ogg', '.mp3', '.wav', '.mp4', '.m4a', '.webm', '.opus'];
    return validExtensions.some(ext => src.endsWith(ext)) && (src.startsWith('/storage/emulated') || src.startsWith('file'));
  };

  // Use in useState
  const [validFileLocations, setValidFileLocations] = useState(() =>
    filterValidFiles(message.file_locations)
  );

  const {
    chatContent, setChatContent, currentSessionIndex,
    setAttachedImages, setAttachedFiles, setEditingMessage,
    setShowCharacterSelection, setIsPermanentCharacterChangeCheckboxVisible,
    setUserInput, setFocusInput, setIsNewSessionFromHere,
    setReadyForRegenerate, setErrorMsg,
    manageProgressText
  } = useContext(StateContext);

  const getSettings = useSettings();
  const currentSessionId = useCurrentSessionId();

  // first we process message as markdown and sanitize it - via dompurify
  const [sanitizedMarkdown, setSanitizedMarkdown] = useState('');
  useEffect(() => {
    const rawMarkdown = marked(message.message);
    const sanitized = DOMPurify.sanitize(rawMarkdown);
    setSanitizedMarkdown(sanitized);
  }, [message.message]);

  // to avoid problems with processing markdown for user message - we treat it differently
  // we will just wrap it in pre tag (so it's not processed as markdown)
  // it will not be pretty but it will work (and it caused quite a bit of problems)
  // for AI response we use sanitized markdown
  const messageContent = message.isUserMessage
    ? formatUserMessage(message.message)
    : sanitizedMarkdown;

  // audio files stored in android phone
  const androidStoredAudioFiles = message.file_locations
    ? message.file_locations.filter(isAndroidStoredAudioFile)
    : [];


  function formatUserMessage(message) {
    return <pre className="user-message-pre">{message}</pre>;
  }

  // get current character and determine value of autoResponse 
  const currentAICharacter = characters.find(char => char.nameForAPI === chatContent[currentSessionIndex].ai_character_name);
  const autoResponseIsFalse = currentAICharacter && !currentAICharacter.autoResponse;

  useEffect(() => {
    if (messageRef.current && !message.isUserMessage) {
      const preBlocks = messageRef.current.querySelectorAll('pre');
      preBlocks.forEach((preBlock, index) => {
        // Check if the pre block is already wrapped
        if (!preBlock.parentNode.classList.contains('code-block-wrapper')) {
          const wrapper = document.createElement('div');
          wrapper.className = 'code-block-wrapper';
          preBlock.parentNode.insertBefore(wrapper, preBlock);
          wrapper.appendChild(preBlock);

          const copyButton = document.createElement('button');
          copyButton.textContent = 'Copy';
          copyButton.className = 'copy-button';
          copyButton.dataset.index = index;
          wrapper.appendChild(copyButton);

          const codeBlock = preBlock.querySelector('code');
          if (codeBlock) {
            Prism.highlightElement(codeBlock);
          }
        }
      });
    }
  }, [sanitizedMarkdown, message.isUserMessage]);

  // Update validImageLocations when message.image_locations changes (for example when it's auto generated image)
  useEffect(() => {
    setValidImageLocations(
      message.image_locations ? message.image_locations.filter(src => src !== "image_placeholder_url") : []
    );
  }, [message.image_locations]);
  useEffect(() => {
    setValidFileLocations(filterValidFiles(message.file_locations));
  }, [message.file_locations]);

  // and listener for click outside (if context menu appears and we click somewhere else we want to hide it)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenu && !event.target.closest('.context-menu')) {
        setContextMenu(null);
        setContextMenuIndex(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu, setContextMenuIndex]);

  // Check if message is empty and imageLocations and fileNames are also empty
  if ((message.message === "" || message.message === ERROR_MESSAGE_FOR_TEXT_GEN) && validImageLocations.length === 0 && (!message.file_locations || message.file_locations.length === 0)) {
    return null;
  }

  // if message is empty, but files are present - it means that it is attached audio file or recording that was transcribed... so we don't need it
  /*if (message.message === "" && message.file_locations && message.file_locations.length > 0) {
    return null;
  }*/

  // show context menu when right clicked
  const handleRightClick = (event) => {
    event.preventDefault();
    setContextMenu(null);

    setContextMenu({
      x: event.clientX,
      y: event.clientY,
    });
    setContextMenuIndex(index);
  };

  // this is used for both code block copy and whole message copy (on right click via context menu)
  // differentiator is codeBlock parameter - behaviour is bit different between two options
  const handleCopy = (e, options = {}) => {
    const { codeBlock = false } = options;
    var contentToCopy = message.message;
    const copyButton = e.target.closest('.copy-button');
    if (codeBlock) {
      contentToCopy = "";
      if (copyButton) {
        const codeElement = copyButton.previousElementSibling.querySelector('code');
        if (codeElement) {
          contentToCopy = codeElement.textContent;
        }
      }
      if (contentToCopy === "") {
        console.error('Failed to copy message');
        return;
      }
    } else {
      // Remove code block delimiters (prefixes , postfixes) when copying the whole message
      contentToCopy = contentToCopy.replace(/```[\w-]*\n([\s\S]*?)\n```/g, '$1');
    }

    if (process.env.NODE_ENV === 'production') {
      navigator.clipboard.writeText(contentToCopy)
        .catch((error) => {
          console.error('Failed to copy message', error);
        });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = contentToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
    if (codeBlock) {
      copyButton.textContent = 'Copied!';
      setTimeout(() => {
        copyButton.textContent = 'Copy';
      }, 2000);
    } else {
      setContextMenu(null);
    }
  };

  const handleEdit = () => {
    // save message index (so we know which message was edited) and messageId from DB - so we can update it later in DB as well
    setEditingMessage({ index, messageId: message.messageId });

    // Convert image locations to attached images format
    const attachedImages = convertFileAndImageLocationsToAttached(message.image_locations);
    const attachedFiles = convertFileAndImageLocationsToAttached(message.file_locations);
    setUserInput(message.message);
    setAttachedImages(attachedImages);
    setAttachedFiles(attachedFiles);
    setFocusInput(true);
    setContextMenu(null);
  };

  const handleRegenerate = () => {
    if (index > 0) {
      const previousMessage = chatContent[currentSessionIndex].messages[index - 1];

      // Check if the previous message is a user message
      if (previousMessage.isUserMessage) {
        setUserInput(previousMessage.message);
        // Convert image locations to attached images format
        const attachedImages = convertFileAndImageLocationsToAttached(previousMessage.image_locations);
        const attachedFiles = convertFileAndImageLocationsToAttached(previousMessage.file_locations);
        setAttachedImages(attachedImages);
        setAttachedFiles(attachedFiles);
        // Set the editing message position
        setEditingMessage({ index: index - 1, messageId: previousMessage.messageId });

        setReadyForRegenerate(true);
      }
    }
    setContextMenu(null);
  };

  const handleNewSessionFromHere = () => {
    // Extract messages up to and including the specified index
    const selectedChatItems = chatContent[currentSessionIndex].messages.slice(0, index + 1).map(item => ({ ...item, messageId: null }));

    const updatedChatContent = [...chatContent];
    // preserve same character
    //updatedChatContent[currentSessionIndex].ai_character_name = chatContent[currentSessionIndex].ai_character_name;
    updatedChatContent[currentSessionIndex].db_session_id = "";  // New session will get a new ID from the backend
    updatedChatContent[currentSessionIndex].messages = selectedChatItems;
    setChatContent(updatedChatContent);
    setShowCharacterSelection(true);
    setIsPermanentCharacterChangeCheckboxVisible(true);
    setIsNewSessionFromHere(true);
    setContextMenu(null);
  };

  const handleRemove = () => {
    // Remove the chat item
    const updatedChatContent = [...chatContent];
    const sessionMessages = updatedChatContent[currentSessionIndex].messages;
    const messageIdsToRemoveFromDB = [];

    if (sessionMessages[index].messageId) {
      messageIdsToRemoveFromDB.push(sessionMessages[index].messageId);
    }
    sessionMessages.splice(index, 1);
    setChatContent(updatedChatContent);
    setContextMenu(null);

    // if next message is AI message - we should remove it too
    if (index < sessionMessages.length && !sessionMessages[index].isUserMessage) {
      if (sessionMessages[index].messageId) {
        messageIdsToRemoveFromDB.push(sessionMessages[index].messageId);
      }
      sessionMessages.splice(index, 1);
      setChatContent(updatedChatContent);
    }

    // Check if session is empty
    const dbMethodToExecute = sessionMessages.length === 0 ? "db_remove_session" : "db_remove_messages";

    const finalInputForDB = {
      session_id: currentSessionId,
      ...(dbMethodToExecute === "db_remove_messages" && { messageIds: messageIdsToRemoveFromDB })
    };

    triggerAPIRequest("api/db", "provider.db", dbMethodToExecute, finalInputForDB, getSettings);

  };

  // show context menu (on right click) - different per user and ai message
  const renderContextMenu = () => {
    if (!contextMenu || contextMenuIndex !== index) return null;

    return (
      <div
        className="context-menu"
        style={{ top: contextMenu.y, left: contextMenu.x, position: 'fixed' }}
      >
        {(message.isUserMessage && (isLastMessage || autoResponseIsFalse)) && (
          <div className="context-menu-item" onClick={handleEdit}>Edit</div>
        )}
        {message.isUserMessage && (
          <div className="context-menu-item" onClick={handleRemove}>Remove</div>
        )}
        {!message.isUserMessage && (
          <>
            {isLastMessage && (
              <div className="context-menu-item" onClick={handleRegenerate}>Regenerate</div>
            )}
          </>
        )}
        <div className="context-menu-item" onClick={handleNewSessionFromHere}>New Session from here</div>
        <div className="context-menu-item" onClick={handleCopy}>Copy</div>
      </div>
    );
  };

  // on attached to message files (pdf or txt) - we can click - then we download them
  const handleFileClick = (index) => {
    const fileLocation = validFileLocations[index];
    const link = document.createElement('a');
    link.href = fileLocation;
    link.download = fileLocation.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Use allSessionImages if available, otherwise fallback to validImageLocations
  const imagesToDisplay = allSessionImages.length > 0 ? allSessionImages : validImageLocations;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesToDisplay.length);
  };
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imagesToDisplay.length) % imagesToDisplay.length);
  };

  const handleImgGenClick = async () => {
    try {
      manageProgressText("show", "Image");
      const imageLocation = await generateImage(message.message, getSettings);
      if (imageLocation) {
        setValidImageLocations(prevLocations => [...prevLocations, imageLocation]);
        // update chat content
        setChatContent((prevChatContent) => {
          // Make sure we update the correct session
          const updatedContent = [...prevChatContent];
          const sessionMessages = updatedContent[currentSessionIndex].messages;
          const currentMessage = sessionMessages[index];

          if (!currentMessage.image_locations.includes(imageLocation)) {
            currentMessage.image_locations.push(imageLocation);
          }
          // save to DB - i had to do it here - because if it was out of setChatContent - it sent outdated data
          updateSessionInDB(updatedContent[currentSessionIndex], currentSessionId, getSettings);
          return updatedContent;
        });
      } else {
        throw new Error("Problem generating image");
      }
    } catch (error) {
      setErrorMsg(error);
      console.error(error);
    } finally {
      manageProgressText("hide", "Image");
    }
  }

  const handleLocationClick = () => {
    if (message.message.startsWith("GPS location:")) {
      const coordinates = message.message.replace("GPS location:", "").trim();
      const googleMapsUrl = `https://www.google.com/maps?q=${coordinates}`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  // IMAGE MODAL
  const handleImageClick = (localIndex) => {
    // Calculate the global index of the clicked image
    const globalIndex = imagesToDisplay.findIndex(src => src === validImageLocations[localIndex]);
    setCurrentImageIndex(globalIndex);
    setIsModalOpen(true);
  };

  return (
    <div className={`chat-message ${message.isUserMessage ? 'user' : 'ai'} ${isFloating ? 'floating-chat-message' : ''}`}
      onContextMenu={handleRightClick}
      ref={messageRef}
    >
      {renderContextMenu()}
      <div className="avatar">
        <img src={avatarSrc} alt="avatar" />
      </div>
      <div className="message-content">
        {message.isUserMessage ? (
          <div>{messageContent}</div>
        ) : (
          <div
            className="message-content"
            dangerouslySetInnerHTML={{ __html: messageContent }}
            ref={messageRef}
            onClick={(e) => handleCopy(e, { codeBlock: true })}
          />
        )}
        {/* Append text if there are Android-stored audio files */}
        {androidStoredAudioFiles.length > 0 && (
          <div className="android-audio-text">
            audio file in android storage (..{androidStoredAudioFiles[0].split('_').pop()})
          </div>
        )}
        {message.aiCharacterName === 'tools_artgen' && !message.isUserMessage ? (
          <button className="img-chat-message-button" onClick={handleImgGenClick}>
            {getSvgIcon('buttonGenerateImage')}
          </button>
        ) : null}
        {message.isGPSLocationMessage ? (
          <button className="img-chat-message-button" onClick={handleLocationClick}>
            {getSvgIcon('buttonGPS')}
          </button>
        ) : null}
        {validImageLocations.length > 0 && (
          <div className="image-container">
            {validImageLocations.map((src, localIndex) => (
              <img key={localIndex} src={src} alt="Chat" onClick={() => handleImageClick(localIndex)} />
            ))}
          </div>
        )}
        {validFileLocations.length > 0 && (
          <div key={index} className="file-placeholder-preview">
            {validFileLocations.map((src, index) => (
              <div key={index} className="file-placeholder" onClick={() => handleFileClick(index)}>
                <span className="pdfName">
                  PDF:<br />
                  {src.split("/")[7].substring(0, 15)}
                  {src.split("/")[7].length > 15 && '...'}
                </span>
              </div>
            ))}
          </div>
        )}
        {message.file_locations && message.file_locations.filter(isValidAudioFile).map((src, index) => (
          <audio key={index} controls>
            <source src={src} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        ))}
        {getGeneralShowMessageInfoBottomRight() && (message.apiTextGenAIModelName || message.created_at) && (
          <div className="date-ai-model-name">
            {message.apiTextGenAIModelName && <span className="generated-info">{message.apiTextGenAIModelName}</span>}
            {message.apiImageGenSettings && (
              <span className="generated-info">
                {JSON.parse(message.apiImageGenSettings).model}
              </span>
            )}
            {message.created_at && <span className="generated-info">{formatDate(message.created_at)}</span>}
          </div>
        )}
      </div>
      {
        isModalOpen && (
          <ChatImageModal
            images={imagesToDisplay}
            currentIndex={currentImageIndex}
            onClose={handleCloseModal}
            onNext={handleNextImage}
            onPrev={handlePrevImage}
            isImage={true}
          />
        )
      }
    </div >
  );
});

export default ChatMessage;

